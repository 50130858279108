body {
    background: black;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

body::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}